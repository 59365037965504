import React, { useEffect, useState } from 'react';
import Page from 'components/Page';
import { useHistory, useParams } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import RouteLocationsList from './RouteLocationListPaginated';
import ConfirmModal from 'components/ConfirmModal';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { TOAST } from 'store/actions';
import {
  Card,
  Button,
  Row,
  Spinner,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
} from 'reactstrap';
import Typography from 'components/Typography';
import classnames from 'classnames';
import {
  fetchRouteDetails,
  updateRoute,
  copyRoute,
  unlinkRoutes,
  getVisitedRouteLocation,
  fetchRouteLocations,
  useInfinitePaginatedRouteLocations,
} from '../../store/requests/routeRequest';
import { getOptimizedRoutes } from '../../store/requests/directionRequest';
import Map from '../../components/Map';
import AddStopModal from '../Modals/AddStop';
import AddRouteLocation from '../Modals/AddRouteLocation';
import RouteVisitedLocations from './RouteVisitLocations';
import { useQueryClient } from 'react-query';
import { useExportRouteLocations } from '../../apis/queries/routes.queries';
import { downloadFile, getFileUrl } from '../../utils';
const getDistance = distance => {
  const oneMeterToMiles = 0.000621371;
  return parseFloat(distance * oneMeterToMiles).toFixed(2);
};
const _ = require('lodash');
const RouteDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const [showSpiner, setShowSpiner] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const [optionDropdownOpen, setOptionDropDownOpen] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showAddStop, setShowAddStop] = useState(false);
  const [markers, setMarkers] = useState([]);
  const toggleDropDown = () => setOpen(!dropdownOpen);
  const toggleOptionDropDown = () => setOptionDropDownOpen(!optionDropdownOpen);
  const [selectedLocationId, setSelectedLocationId] = useState();
  const [showLocationDelete, setShowLocationDelete] = useState();
  const [showAddLocation, setShowAddLocation] = useState(false);
  const [orderIndex, setOrderIndex] = useState();
  const [activeTab, setActiveTab] = useState('1');
  const [visitedLocations, setVisitedLocations] = useState([]);
  const [locations, setLocations] = useState([]);
  const queryClient = useQueryClient();
  const exportLocationsHandler = useExportRouteLocations();

  const locationsQuery = useInfinitePaginatedRouteLocations({
    limit: 10,
    page: 1,
    routeId: id,
  });

  const formatRouteLocationsList = () =>
    (locationsQuery.data &&
      locationsQuery.data.pages
        .map(object => {
          return object && object.data;
        })
        .reduce(
          (accumulator, currentArray) => accumulator.concat(currentArray),
          [],
        )) ||
    [];

  useEffect(() => {
    const locationsList = formatRouteLocationsList();
    setLocations(locationsList || []);
    changeMarker(locationsList);
  }, [locationsQuery.data]);

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      if (tab === '1') changeMarker(locations);
      else if (tab === '2') {
        const locations = visitedLocations.map(items => ({
          location: items.location[0],
        }));
        changeMarker(locations);
      }
    }
  };
  useEffect(() => {
    getDetails();
  }, []);

  const changeMarker = locations => {
    setMarkers(
      locations.map(item => {
        if (!item.locations.location) return null;
        return {
          id: item._id,
          isVisited: item.isVisited,
          pos: {
            lat: item.locations.location.coordinates[1],
            lng: item.locations.location.coordinates[0],
          },
        };
      }),
    );
  };

  const getDetails = async (recalculate = false) => {
    setShowSpiner(true);
    let res = await fetchRouteDetails(id);
    let visitedLocation = await getVisitedRouteLocation(id);
    if (res.name !== 'Error') {
      if (recalculate) {
        let points = locations.map(
          items => items.locations.location.coordinates,
        );
        let resp = await getOptimizedRoutes(points);
        if (resp && !resp.paths) {
          dispatch({
            type: TOAST.SHOW,
            load: {
              type: 'error',
              title: 'Error',
              message: resp.message
                ? resp.message
                : 'Opps! Something went wrong',
              show: true,
            },
          });
        } else {
          res.data.estimatedTime = parseInt(resp.paths[0].time / 1000);
          res.data.distance = resp.paths[0].distance;

          await updateRoute(id, {
            estimatedTime: res.data.estimatedTime,
            distance: res.data.distance,
          });
        }
      }
      setDetails(res.data);
    }
    if (visitedLocation.name !== 'Error') {
      if (
        visitedLocation.data &&
        visitedLocation.data[0] &&
        visitedLocation.data[0].visits
      ) {
        setVisitedLocations(visitedLocation.data[0].visits);
      }
    }
    setShowSpiner(false);
  };

  const cloneRoute = async () => {
    let resp = await copyRoute(id);
    if (resp.name !== 'Error') {
      showSuccessMessage('Route Copied!');
      history.goBack();
    }
  };
  const showSuccessMessage = message => {
    dispatch({
      type: TOAST.SHOW,
      load: {
        type: 'success',
        title: 'Success',
        message: message,
        show: true,
      },
    });
  };
  const changeStatus = async status => {
    let resp = await updateRoute(id, { status });
    if (resp && resp.name !== 'Error') {
      let cloneData = _.cloneDeep(details);
      cloneData.status = status;
      setDetails(cloneData);
      showSuccessMessage('Location updated!');
    }
  };

  const confirmDelete = async () => {
    let res = await updateRoute(id, { status: 'inactive' });
    if (res && res.name !== 'Error') {
      setShowConfirmDelete(false);
      showSuccessMessage('Route Deleted!');
      history.goBack();
    }
  };

  const loadMore = () => locationsQuery.fetchNextPage();

  const onConfirmRemove = async () => {
    let res = await unlinkRoutes(id, selectedLocationId);
    if (res.name !== 'Error') {
      queryClient.invalidateQueries(['route-location-maps-paginated']);
      getDetails(true);
      setShowLocationDelete(false);
      showSuccessMessage('Location Deleted!');
    }
  };

  const onRemove = async locationId => {
    setSelectedLocationId(locationId);
    setShowLocationDelete(true);
  };
  function getLocations() {
    if (details && Array.isArray(locations)) {
      return locations.map(item => {
        return {
          ...item.locations,
          id: item.locations._id,
          order: item.order,
        };
      });
    }
    return [];
  }

  const convertTime = time => {
    var seconds = time;
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    // seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    let timeString = '';
    if (days > 0) timeString += `${days} days `;
    if (hours > 0) timeString += `${hours} hours `;
    if (minutes > 0) timeString += `${minutes} minutes `;
    return timeString;
  };

  const renderListsTab = () => (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
            }}
          >
            Locations
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2');
            }}
          >
            Visits
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="mt-2">
        <TabPane tabId="1">
          <RouteLocationsList
            locations={locations}
            status={details.status}
            onRemoveLocation={onRemove}
            routeId={id}
            onAddLocation={value => {
              setOrderIndex(value);
              setShowAddLocation(true);
            }}
            hasNextPage={locationsQuery.hasNextPage}
            loadMore={loadMore}
          />
        </TabPane>
        <TabPane tabId="2">
          {activeTab === '2' ? (
            <Row>
              <Col xs={12}>
                <RouteVisitedLocations routeId={id} />
              </Col>
            </Row>
          ) : null}
        </TabPane>
      </TabContent>
    </>
  );
  // get dropdown items
  const dropdownItems = details => {
    if (!details || !details.status) {
      return [];
    }
    if (details.status === 'active') {
      return (
        <DropdownMenu>
          <DropdownItem onClick={() => changeStatus('inactive')}>
            Inactive
          </DropdownItem>
          <DropdownItem onClick={() => changeStatus('completed')}>
            Completed
          </DropdownItem>
          <DropdownItem onClick={() => changeStatus('paused')}>
            Paused
          </DropdownItem>
        </DropdownMenu>
      );
    } else if (details.status === 'inactive') {
      return (
        <DropdownMenu>
          <DropdownItem onClick={() => changeStatus('active')}>
            Active
          </DropdownItem>
          <DropdownItem onClick={() => changeStatus('paused')}>
            Paused
          </DropdownItem>
          <DropdownItem onClick={() => changeStatus('completed')}>
            Completed
          </DropdownItem>
        </DropdownMenu>
      );
    } else if (details.status === 'completed') {
      return (
        <DropdownMenu>
          <DropdownItem onClick={() => changeStatus('active')}>
            Active
          </DropdownItem>
          <DropdownItem onClick={() => changeStatus('inactive')}>
            Inactive
          </DropdownItem>
          <DropdownItem onClick={() => changeStatus('paused')}>
            Paused
          </DropdownItem>
        </DropdownMenu>
      );
    } else if (details.status === 'paused') {
      return (
        <DropdownMenu>
          <DropdownItem onClick={() => changeStatus('active')}>
            Active
          </DropdownItem>
          <DropdownItem onClick={() => changeStatus('inactive')}>
            Inactive
          </DropdownItem>
          <DropdownItem onClick={() => changeStatus('completed')}>
            Completed
          </DropdownItem>
        </DropdownMenu>
      );
    } else if (details.status === 'new') {
      return (
        <DropdownMenu>
          <DropdownItem onClick={() => changeStatus('active')}>
            Active
          </DropdownItem>
          <DropdownItem onClick={() => changeStatus('inactive')}>
            Inactive
          </DropdownItem>
          <DropdownItem onClick={() => changeStatus('completed')}>
            Completed
          </DropdownItem>
        </DropdownMenu>
      );
    }
  };

  const exportLocations = () => {
    exportLocationsHandler.mutate(id, {
      onSuccess: res => {
        downloadFile(getFileUrl(res.link));
        dispatch({
          type: TOAST.SHOW,
          load: {
            type: 'success',
            title: 'Success',
            message: 'Locations exported successfully',
            show: true,
          },
        });
      },
    });
  };

  return (
    <Page className="DashboardPage" title="Route Details">
      {showSpiner || !details ? (
        <Spinner
          animation="border"
          className="page-spinner"
          variant="info"
          size="lg"
        />
      ) : (
        <Card className="page-card box-shadow mb-3">
          <Row>
            <div className="w-100">
              <div className="fillfree mb-4">
                <div className="widSec1">
                  <Typography type="h4" className="font-weight-600">
                    {details.name}
                  </Typography>
                </div>
                <div>
                  <Typography className="text-muted mb-0">Distance</Typography>
                  <Typography className="font-weight-bold m-0">
                    {`${getDistance(details.distance)} miles`}
                  </Typography>
                </div>
                <div>
                  <Typography className="text-muted mb-0">
                    Estimated time
                  </Typography>
                  <Typography className="font-weight-bold m-0">
                    {details.estimatedTime &&
                      convertTime(details.estimatedTime)}
                  </Typography>
                </div>
                <div>
                  <Typography className="text-muted mb-0">
                    Visited Stops
                  </Typography>
                  <Typography className="font-weight-bold m-0">
                    {locations &&
                      locations.filter(items => items.isVisited).length}
                  </Typography>
                </div>
                {details.status !== 'completed' ? (
                  <div>
                    <Typography className="text-muted mb-0">
                      Left to Visit
                    </Typography>
                    <Typography className="font-weight-bold m-0">
                      {locations &&
                        locations.filter(items => !items.isVisited).length}
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <Typography className="text-muted mb-0">
                      Time Taken
                    </Typography>
                    <Typography className="font-weight-bold m-0">
                      {convertTime(
                        moment(details.endTime).diff(
                          moment(details.startTime),
                          'second',
                        ),
                      )}
                    </Typography>
                  </div>
                )}

                {details.status === 'completed' && (
                  <div>
                    <Typography className="text-muted mb-0">
                      Completed On
                    </Typography>
                    <Typography className="font-weight-bold m-0">
                      {moment(details.endTime).format('MM-DD-YYYY')}
                    </Typography>
                  </div>
                )}

                <div>
                  <Typography className="text-muted mb-0">
                    Created At
                  </Typography>
                  <Typography className="font-weight-bold m-0">
                    {moment(details.createdAt).format('MM-DD-YYYY')}
                  </Typography>
                </div>

                <div>
                  <Typography className="text-muted mb-0">Status</Typography>

                  <ButtonDropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropDown}
                    className="dropdown-options"
                  >
                    <DropdownToggle
                      caret
                      className="text-capitalize p-0 font-weight-bold "
                      color="none"
                    >
                      {/* <Typography className="font-weight-bold m-0 text-capitalize"> */}
                      {details.status}
                      {/* </Typography> */}
                    </DropdownToggle>
                    {dropdownItems(details)}
                  </ButtonDropdown>
                </div>

                <div className="d-flex">
                  <Button
                    color="secondary"
                    className="pull-right mb-4  mr-3"
                    onClick={exportLocations}
                  >
                    Export{' '}
                    {exportLocationsHandler.isLoading && <Spinner size="sm" />}
                  </Button>
                  {details.status !== 'completed' && (
                    <Button
                      color="primary"
                      onClick={() => setShowAddStop(true)}
                      disabled={!locations || !locations.length}
                    >
                      Add Stop
                    </Button>
                  )}

                  {!locations || !locations.length ? null : (
                    <ButtonDropdown
                      isOpen={optionDropdownOpen}
                      toggle={toggleOptionDropDown}
                      className="pl-3 "
                    >
                      <DropdownToggle
                        // caret
                        className="text-capitalize p-0 font-weight-bold btnFont"
                        color="none"
                      >
                        <BsThreeDotsVertical />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => cloneRoute()}>
                          Copy route
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  )}
                </div>
              </div>
            </div>
          </Row>

          <Row className="map-container-lanscape">
            <Map markers={markers} />
          </Row>
          <div className="mt-4">
            {/* tab component */} {renderListsTab()}
          </div>
        </Card>
      )}
      <ConfirmModal
        show={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        title="Delete Route"
        body="Are you sure?"
        actions={[
          <Button onClick={() => confirmDelete()} color="danger">
            Delete
          </Button>,
          <Button
            color="primary"
            onClick={() => {
              setShowConfirmDelete(false);
            }}
          >
            Cancel
          </Button>,
        ]}
      />
      <ConfirmModal
        show={showLocationDelete}
        onClose={() => setShowLocationDelete(false)}
        title="Delete location"
        body="Are you sure?"
        actions={[
          <Button onClick={() => onConfirmRemove()} color="danger">
            Delete
          </Button>,
          <Button
            color="primary"
            onClick={() => {
              setShowLocationDelete(false);
            }}
          >
            Cancel
          </Button>,
        ]}
      />
      <AddStopModal
        locations={getLocations()}
        show={showAddStop}
        route={details}
        getDetails={getDetails}
        showSuccessMessage={showSuccessMessage}
        onClose={() => setShowAddStop(false)}
        routeId={id}
      />
      <AddRouteLocation
        locations={getLocations()}
        show={showAddLocation}
        route={details}
        getDetails={getDetails}
        showSuccessMessage={showSuccessMessage}
        onClose={() => setShowAddLocation(false)}
        order={orderIndex}
      />
    </Page>
  );
};

export default RouteDetails;
