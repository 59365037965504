import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import Typography from 'components/Typography';
import { GrLocation } from 'react-icons/gr';
import { FaTrashAlt } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { TiArrowSortedDown } from 'react-icons/ti';
const RouteLocationList = ({
  locations = [],
  onRemoveLocation = null,
  routeId,
  track = false,
  onAddLocation,
  status,
  hasNextPage = false,
  loadMore = () => {},
}) => {
  const history = useHistory();
  return (
    <div>
      {locations.map((items, index) => (
        <Row key={items.id || items._id}>
          <div className="timeline-container m-0">
            <div
              className={`time-bubble m-0 ${
                items.isVisited && 'time-bubble-dark'
              }`}
            >
              <p className="text-white bubble-text m-0">{items.order + 1}</p>
            </div>
            <div
              className={`timeline-line ${
                items.isVisited && 'timeline-line-dark'
              }`}
            />
          </div>
          <Col>
            {items.locations && items.locations.description}
            {status !== 'completed' && (
              <Button
                color="none"
                className="box-shadow sd-btn mt-3"
                size="sm"
                // onClick={() => history.push(`/add-edit-location/${routeId}/${index}`)}
                onClick={() => onAddLocation(items.order)}
              >
                <FiPlus />
              </Button>
            )}
          </Col>
          <Col>
            <Row>
              <Col xs={1}>
                <GrLocation />
              </Col>
              <Col>
                <div style={{ fontSize: '14px' }} className="text-capitalize">
                  {items.locations && items.locations.address}
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Typography className="text-muted m-0 p-0">Type</Typography>
            <div style={{ fontSize: '14px' }} className="text-capitalize">
              {(items.locations.locationType &&
                items.locations.locationType.name) ||
                (items.locations.type && items.locations.type)}
            </div>
          </Col>
          <Col style={{ fontSize: '14px' }}>
            <Typography className="text-muted m-0 p-0">Last visit</Typography>
            {items.arrivalTime
              ? moment(items.arrivalTime).format('MM-DD-YYYY')
              : 'NA'}
          </Col>
          <Col style={{ fontSize: '14px' }}>
            <Typography className="text-muted m-0 p-0">Arrived</Typography>

            {items.arrivalTime
              ? moment(items.arrivalTime).format('hh:mm a')
              : 'NA'}
          </Col>
          <Col>
            <Typography className="text-muted m-0 p-0">Departure</Typography>

            {items.departureTime
              ? moment(items.departureTime).format('hh:mm a')
              : 'NA'}
          </Col>
          <Col xs={2}>
            <Button
              color="none"
              className="pl-3 pr-3 sd-btn"
              onClick={() =>
                items.locations &&
                history.push(
                  `/location-details/${
                    items.locations.id || items.locations._id
                  }`,
                )
              }
            >
              <GrLocation />
              <div>View details</div>
            </Button>
          </Col>
          <Col>
            {!items.isVisited && status !== 'completed' && (
              <Button
                color="none"
                onClick={() =>
                  onRemoveLocation(items.locations.id || items.locations._id)
                }
              >
                <FaTrashAlt color="red" />
              </Button>
            )}
          </Col>
        </Row>
      ))}
      {hasNextPage ? (
        <Button
          color="transparent"
          onClick={loadMore}
          className="sd-btn mx-auto shadow-none border"
        >
          Load More <TiArrowSortedDown />
        </Button>
      ) : null}
    </div>
  );
};

export default RouteLocationList;
