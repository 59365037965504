import React from 'react';
import ReactSelect from 'react-select';
import { Spinner } from 'reactstrap';

const Select = ({
  options,
  hasNextPage,
  isLoading,
  loadMore,
  style = {},
  ...props
}) => {
  const Menu = props => {
    const { children, innerProps } = props;

    return (
      <div {...innerProps} className="select-menu">
        {children}
        {hasNextPage ? (
          <button
            type="button"
            className="load-more"
            disabled={isLoading}
            onClick={loadMore}
          >
            {isLoading ? <Spinner size="sm" color="primary" /> : 'Load More'}
          </button>
        ) : null}
      </div>
    );
  };

  return (
    <ReactSelect
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder="Select Type"
      styles={{
        menu: provided => ({
          ...provided,
          zIndex: 100,
        }),
        menuList: provided => ({
          ...provided,
          backgroundColor: '#f0f0f0',
          maxHeight: 200,
          color: '#000',
        }),
        multiValue: provided => ({
          ...provided,
          height: 22,
          paddingLeft: 12,
          borderRadius: 4,
          backgroundColor: '#000',
        }),
        multiValueLabel: provided => ({
          ...provided,
          fontSize: 12,
          fontWeight: 500,
          fontFamily: 'DM Sans,sans-serif',
          color: '#fff',
        }),
        option: provided => ({
          ...provided,
        }),
        control: (provided, state) => ({
          ...provided,
          borderColor: state.isFocused ? '#28376e' : provided.borderColor,
          outline: 0,
          boxShadow: 'none',
          '&:hover': {
            borderColor: state.isFocused ? '#28376e' : '#ced4da',
          },
        }),
        container: provided => ({
          ...provided,
          ...style.container,
          width: '100%',
        }),
      }}
      noOptionsMessage={() => 'No Data Found'}
      components={{ Menu, IndicatorSeparator: null, LoadingIndicator: null }}
      {...props}
    />
  );
};

export default Select;
