import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Button,
  Spinner,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TOAST } from 'store/actions';
import Map from '../../components/Map';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { MdMyLocation } from 'react-icons/md';
import TemplateFilterModal from 'pages/Modals/TemplateFilterModal';
import MultiselectDropDown from '../../components/MultiSelect';
import {
  addRoute,
  createRouteTemplate,
  deleteRouteTemplate,
  fetchLocationsByFilter,
  fetchRouteTemplates,
} from '../../store/requests/routeRequest';
import { debounce } from 'lodash';
import { getOptimizedRoutes } from '../../store/requests/directionRequest';
import ConfirmModal from 'components/ConfirmModal';
import { fetchOfficeAgents } from 'store/requests/agentRequest';
import {
  fetchOffices,
  fetchBranchOffices,
} from 'store/requests/officeRequests';
import MultiSelectDropDown from 'components/MultiSelect';
import RouteLocationsList from './RouteLocationList';
import Select from '../../components/shared/FormInputs/Select';
import { useInfiniteReportsByOffices } from '../../apis/queries/offices.queries';
import {
  useInfiniteRouteTemplateByUserId,
  useInfiniteUsers,
} from '../../apis/queries/users.queries';

const _ = require('lodash');
const initialFilter = {
  name: '',
  limit: 1,
  latitude: null,
  longitude: null,
  kilometers: 1,
  params: {
    type: [],
    zip: [],
    city: [],
    chain: [],
    dbaName: [],
    legal: [],
    lastVisit: 0,
  },
};
const CreateRoute = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.userData);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    templateId: '',
    userId: '',
    locationIds: [],
  });
  const [mapCenter, setMapCenter] = useState({});
  const [markers, setMarkers] = useState([]);

  const [updatedOfficeList, setUpdatedOfficeList] = useState([]);
  const [updatedAgentList, setUpdatedAgentList] = useState([]);
  const [updatedRouteTemplateList, setUpdatedRouteTemplateList] = useState([]);

  const [selectedOfficeItem, setSelectedOfficeItem] = useState();
  const [selectedAgentItem, setSelectedAgentItem] = useState();

  const [searchableOfficeInput, setSearchableOfficeInput] = useState(undefined);
  const [searchableAgentInput, setSearchableAgentInput] = useState(undefined);
  const [searchableRouteTemplateInput, setSearchableRouteTemplateInput] =
    useState(undefined);

  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
  const [selectedTemplateId, setSelectedTemplateID] = useState();
  const [filterData, setFilterData] = useState(initialFilter);
  const [showModal, setShowModal] = useState(false);
  const [selectedOfficeId, setSelectedOfficeId] = useState();
  const [offices, setOffices] = useState([]);
  const [agents, setAgents] = useState([]);
  const [showSearchLoader, setShowSearchLoader] = useState(false);
  const [routeLocations, setRouteLocations] = useState([]);

  const debouncedSearchableOfficeInput = debounce(
    value => setSearchableOfficeInput(value),
    500,
  );

  const debouncedSearchableAgentInput = debounce(
    value => setSearchableAgentInput(value),
    500,
  );

  const debouncedSearchableRouteTemplateInput = debounce(
    value => setSearchableRouteTemplateInput(value),
    500,
  );

  const handleSearchableOfficeInput = value =>
    debouncedSearchableOfficeInput(value);

  const handleSearchableAgentInput = value =>
    debouncedSearchableAgentInput(value);

  const handleSearchableRouteTemplateInput = value =>
    debouncedSearchableRouteTemplateInput(value);

  const reportsByOfficeQuery = useInfiniteReportsByOffices({
    limit: 10,
    page: 1,
    search: searchableOfficeInput || undefined,
  });

  const agentsQuery = useInfiniteUsers(
    {
      limit: 10,
      page: 1,
      role: 'agent',
      search: searchableAgentInput || undefined,
      officeId:
        selectedOfficeItem && selectedOfficeItem.value
          ? selectedOfficeItem.value
          : undefined,
    },
    !!(selectedOfficeItem && selectedOfficeItem.value),
  );

  const routeTemplateQuery = useInfiniteRouteTemplateByUserId(
    userData.id,
    {
      limit: 10,
      page: 1,
      search: searchableRouteTemplateInput || undefined,
    },
    !!userData.id,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(
    function () {
      if (userData.id) {
        // fetchRouteTemplates(userData.id).then(res => {
        //   res.data.unshift({
        //     id: 'create',
        //     name: 'Create New Location Filter',
        //   });
        //   setTemplates(res.data);
        // });
      }
      if (userData.role) {
        getOfficeAndAgent();
      }
    },
    [userData],
  );

  useEffect(
    function searchRoutesOnTemplateChange() {
      if (Object.keys(selectedTemplate).length !== 0 && !selectedTemplate._id) {
        searchLocations();
      }
    },
    [filterData, selectedTemplate],
  );

  const getOfficeAndAgent = async () => {
    let officeResp;
    if (userData.role === 'admin') {
      officeResp = await fetchOffices();
    } else if (userData.role === 'office-manager' && userData.officeID) {
      officeResp = await fetchBranchOffices(userData.officeID);
    }

    if (officeResp && officeResp.name !== 'Error') {
      // if (userData.role === 'office-manager') {
      //   officeResp.data = officeResp.data.map(items => items.branch);
      // } else {
      //   officeResp.data = officeResp.data.map(items => ({
      //     ...items,
      //     id: items._id,
      //   }));
      // }
      // setOffices(officeResp.data);
    }
  };

  useEffect(() => {
    if (selectedOfficeId) {
      getOfficeAgents();
      searchLocations();
    }
  }, [selectedOfficeId]);

  const getOfficeAgents = async () => {
    let agentResp = await fetchOfficeAgents(selectedOfficeId);
    if (agentResp && agentResp.name !== 'Error') {
      setAgents(agentResp.data);
    }
  };
  const changeValue = (field, value) => {
    let cloneData = _.cloneDeep(formData);
    cloneData[field] = value;

    setFormData(cloneData);
  };
  useEffect(() => {
    if (formData.userId) {
      searchLocations();
    }
  }, [formData.userId]);

  const validate = () => {
    let validation = {
      isValid: true,
      message: '',
    };

    let { name, locationIds, userId } = formData;

    if (!name || name.trim().length < 3) {
      validation.isValid = false;
      validation.message = 'Route name should have atleast 3 characters';
    } else if (locationIds.length === 0) {
      validation.isValid = false;
      validation.message = 'There are no locations selected in this route';
    } else if (!selectedOfficeId || selectedOfficeId.length === 0) {
      validation.isValid = false;
      validation.message = 'No office selected';
    } else if (!userId || userId.length === 0) {
      validation.isValid = false;
      validation.message = 'No Agent selected';
    }
    return validation;
  };

  const getOptimizedRoute = async data => {
    if (!data || data.length === 0)
      return showErrorMessage('No location found');
    let points = data.map(items => items.location.coordinates);
    if (points.length < 2) {
      setMarkers([
        {
          id: data[0]._id,
          pos: {
            lat: data[0].location.coordinates[1],
            lng: data[0].location.coordinates[0],
          },
        },
      ]);
      setFormData({
        ...formData,
        locationIds: [data[0]._id],
        estimatedTime: 0,
        distance: 0,
      });
      setRouteLocations([{ location: data[0] }]);
      return;
    }

    let resp = await getOptimizedRoutes(points);

    if (resp && resp.name !== 'Error') {
      if (!resp.paths) {
        return dispatch({
          type: TOAST.SHOW,
          load: {
            type: 'error',
            title: 'Error',
            message: resp.message ? resp.message : 'Opps! Something went wrong',
            show: true,
          },
        });
      }
      let sortedPoints = resp.paths[0].points_order.map(items => data[items]);

      const locationIds = [];
      const markerList = [];
      const locationList = [];

      sortedPoints.forEach(item => {
        locationIds.push(item._id);
        markerList.push({
          id: item._id,
          pos: {
            lat: item.location.coordinates[1],
            lng: item.location.coordinates[0],
          },
        });
        locationList.push({
          location: item,
        });
      });
      setRouteLocations(locationList);
      setMarkers(markerList);
      setFormData({
        ...formData,
        locationIds: locationIds,
        estimatedTime: parseInt(resp.paths[0].time / 1000),
        distance: resp.paths[0].distance,
      });
    }
  };

  const onSave = async e => {
    // setShowSearchLoader(true);
    if (e) e.preventDefault();
    let validateData = validate();
    if (!validateData.isValid) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: validateData.message,
          show: true,
        },
      });
    } else {
      const locations = {
        locations: formData.locationIds,
      };

      // console.log(
      //   formData.name,
      //   locations,
      //   userData.id,
      //   formData.distance,
      //   selectedOfficeId,
      //   formData.estimatedTime,
      //   formData.userId,
      // );
      // return;
      let resp = await addRoute(
        formData.name,
        locations,
        userData.id,
        formData.distance,
        selectedOfficeId,
        formData.estimatedTime,
        formData.userId,
      );
      if (resp.name !== 'Error') {
        dispatch({
          type: TOAST.SHOW,
          load: {
            type: 'success',
            title: 'Success',
            message: 'Route added!',
            show: true,
          },
        });
        history.goBack();
      }
    }
  };

  const changePlace = async placeData => {
    const results = await geocodeByAddress(placeData.description);
    const latLng = await getLatLng(results[0]);
    setMapCenter({ ...latLng });
  };

  const onCancel = () => {
    history.goBack();
  };

  const changeFilter = (key, value) => {
    let data = _.cloneDeep(filterData);
    if (key.includes('.')) {
      key = key.split('.');
      data[key[0]][key[1]] = value;
    } else {
      if (key === 'latlng') {
        data['latitude'] = value.lat;
        data['longitude'] = value.lng;
      } else {
        data[key] = value;
      }
    }

    setFilterData(data);
  };

  function isFilterDataValid(checkName = false) {
    if (checkName && (filterData.name === '' || !filterData.name)) {
      showErrorMessage('Please enter valid template name');
      return;
    }
    if (filterData.limit <= 0) {
      showErrorMessage('Maximum number of stops need to be more than 0');
      return false;
    } else if (!filterData.latitude || !filterData.longitude) {
      showErrorMessage('Please select a start or end location!');
      return false;
    }
    return true;
  }
  const isValidKey = values => {
    if (Array.isArray(values) && values.length > 0) {
      return true;
    }
    return false;
  };
  const searchLocations = () => {
    setShowSearchLoader(true);

    if (!isFilterDataValid()) {
      setShowSearchLoader(false);

      return;
    }
    let { params } = filterData;

    if (!isValidKey(params.type)) {
      delete params.type;
    }
    if (!isValidKey(params.zip)) {
      delete params.zip;
    }
    if (!isValidKey(params.city)) {
      delete params.city;
    }
    if (!isValidKey(params.chain)) {
      delete params.chain;
    }
    if (!isValidKey(params.dbaName)) {
      delete params.dbaName;
    }
    if (!isValidKey(params.legal)) {
      delete params.legal;
    }
    if (params.lastVisit <= 0) {
      delete params.lastVisit;
    }
    if (!params) {
      params = {};
    }
    if (selectedOfficeId) filterData.officeId = selectedOfficeId;
    if (formData.userId) filterData.userId = formData.userId;
    setFormData({ ...formData, locationIds: [] });
    fetchLocationsByFilter(filterData)
      .then(res => {
        if (res && res.data && res.data.length === 0) {
          setMarkers([]);
          setRouteLocations([]);
          showErrorMessage('No locations fetched!');
        } else {
          getOptimizedRoute(res.data);
        }
        setShowModal(false);
        setShowSearchLoader(false);
      })
      .catch(err => {
        setShowSearchLoader(false);
      });
  };

  const showErrorMessage = message => {
    dispatch({
      type: TOAST.SHOW,
      load: {
        type: 'error',
        title: 'Error',
        message: message,
        show: true,
      },
    });
  };

  function saveTemplate() {
    if (!isFilterDataValid(true)) {
      return;
    }

    const body = {
      name: filterData.name,
      filters: filterData,
      isGlobal: true,
      status: 'active',
      userId: userData.id,
    };
    createRouteTemplate(userData.id, body).then(res => {
      const templateList = [...templates];
      templateList.push(res.data);
      setTemplates(templateList);
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'success',
          title: 'Success',
          message: 'Template created!',
          show: true,
        },
      });
      setShowModal(false);
      useTemplate({});
      routeTemplateQuery.refetch();
    });
  }

  function useTemplate(template) {
    if (!template || template.id === 'create') {
      return;
    }
    setSelectedTemplate(template);
    setFilterData(template.filters);
  }

  //FIXME: uncomment below if needed
  // function getDistance(distanceResponse) {
  //   if (!distanceResponse) {
  //     setDistance(0);
  //     return;
  //   }
  //   if (distanceResponse.routes.length === 0) {
  //     showErrorMessage('No routes available!');
  //     return;
  //   }
  //   const distance = parseInt(
  //     distanceResponse.routes[0].legs[0].distance.value / 1000,
  //   );

  //   setDistance(distance);
  // }

  const onDeleteTemplate = id => {
    setSelectedTemplateID(id);
    setShowDeleteTemplateModal(true);
  };

  const confirmDeleteTemplate = async () => {
    let resp = await deleteRouteTemplate(selectedTemplateId);
    if (resp && resp.name !== 'Error') {
      setTemplates(templates.filter(items => items.id !== selectedTemplateId));
      setSelectedTemplateID();
      setShowDeleteTemplateModal(false);
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'success',
          title: 'Success',
          message: 'Template deleted!',
          show: true,
        },
      });
    }
  };

  const customItemRenderer = ({ item, itemIndex, props, state, methods }) => {
    if (item.id === 'create') {
      return (
        <>
          <Row className="m-0 pt-3 pb-3 pl-2 template-item template-dropdown-item">
            <Col
              xs={10}
              className="template-content"
              onClick={() => {
                // methods.addItem(item);
                setSelectedTemplate({});
                setFilterData(initialFilter);
                setShowModal(true);
              }}
            >
              {item.name}
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <Row className="m-0 p-2 template-item template-dropdown-item">
          <Col
            onClick={() => methods.addItem(item)}
            xs={10}
            className="template-content"
          >
            {item.name}
          </Col>
          <Button color="none" onClick={() => onDeleteTemplate(item.id)}>
            X
          </Button>
        </Row>
      );
    }
  };

  const formatReportByOfficeList = () =>
    (reportsByOfficeQuery.data &&
      reportsByOfficeQuery.data.pages
        .map(object => {
          return object && object.data;
        })
        .reduce(
          (accumulator, currentArray) => accumulator.concat(currentArray),
          [],
        )
        .map(({ _id, name }) => ({ value: _id, label: name }))) ||
    [];

  const formatAgentList = () =>
    (agentsQuery.data &&
      agentsQuery.data.pages
        .map(object => {
          return object && object.data;
        })
        .reduce(
          (accumulator, currentArray) => accumulator.concat(currentArray),
          [],
        )
        .map(({ _id, displayName }) => ({
          value: _id,
          label: displayName,
        }))) ||
    [];

  const formatRouteTemplateList = () =>
    (routeTemplateQuery.data &&
      routeTemplateQuery.data.pages
        .map(object => {
          return object && object.data;
        })
        .reduce(
          (accumulator, currentArray) => accumulator.concat(currentArray),
          [],
        )
        .map(template => ({
          value: template,
          label: template.name,
        }))) ||
    [];

  const loadMoreReportByOffice = event => {
    reportsByOfficeQuery.hasNextPage && reportsByOfficeQuery.fetchNextPage();
    event.stopPropagation();
  };

  const loadMoreAgents = event => {
    agentsQuery.hasNextPage && agentsQuery.fetchNextPage();
    event.stopPropagation();
  };

  const loadMoreRouteTemplate = event => {
    routeTemplateQuery.hasNextPage && routeTemplateQuery.fetchNextPage();
    event.stopPropagation();
  };

  useEffect(() => {
    setUpdatedOfficeList(formatReportByOfficeList());
  }, [reportsByOfficeQuery.data]);

  useEffect(() => {
    setUpdatedAgentList(formatAgentList());
  }, [agentsQuery.data]);

  useEffect(() => {
    setUpdatedRouteTemplateList(formatRouteTemplateList());
  }, [routeTemplateQuery.data]);
  return (
    <Page className="DashboardPage" title="Create New Route">
      <Card className="page-card box-shadow">
        <Form onSubmit={e => e.preventDefault()}>
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Label>Set Current Location</Label>
                {/* <span className="required">*</span> */}
                <div>
                  <GooglePlacesAutocomplete
                    inputClassName="form-control"
                    placeholder="Search location"
                    onSelect={changePlace}
                    autocompletionRequest={{
                      fields: [
                        'address_component',
                        'name',
                        'geometry',
                        'formatted_address',
                      ],
                      types: ['establishment'],
                    }}
                  />

                  <div className="location-icon">
                    <MdMyLocation />
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label>Route Name</Label>
                <span className="required">*</span>
                <Input
                  placeholder="Enter Route Name"
                  value={formData.name}
                  onChange={e => changeValue('name', e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label> Select Location Filter</Label>
                {/* <MultiselectDropDown
                  options={templates}
                  placeholder="Select Location Filter"
                  labelField="name"
                  valueField="id"
                  onSelect={data => useTemplate(data)}
                  value={templates.filter(
                    items => items.id === selectedTemplate.id,
                  )}
                  itemRenderer={customItemRenderer}
                  separator={true}
                /> */}

                <Select
                  options={[
                    { label: 'Create New Location Filter', value: '' },
                    ...updatedRouteTemplateList,
                  ]}
                  placeholder="Select Location Filter"
                  loadMore={loadMoreRouteTemplate}
                  isLoading={routeTemplateQuery.isFetching}
                  hasNextPage={routeTemplateQuery.hasNextPage}
                  onInputChange={handleSearchableRouteTemplateInput}
                  onChange={val => {
                    if (val && typeof val.value === 'string') {
                      setShowModal(true);
                      setFilterData({ ...filterData, name: '' });
                    } else if (val && typeof val.value._id === 'string') {
                      useTemplate(val.value);
                    } else {
                      setSelectedTemplate({});
                    }
                  }}
                  value={updatedRouteTemplateList.filter(
                    items => items.value._id === selectedTemplate._id,
                  )}
                  isClearable
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label>Select Office</Label>
                <span className="required">*</span>
                {/* <MultiSelectDropDnullown
                  options={offices}
                  placeholder="Select office"
                  labelField="name"
                  valueField="id"
                  onSelect={data => {
                    changeValue('userId', '');
                    setSelectedOfficeId(data[0].id);
                  }}
                  value={
                    offices && offices.length > 0
                      ? offices.filter(items => items.id === selectedOfficeId)
                      : []
                  }
                  searchable={false}
                /> */}

                <Select
                  options={updatedOfficeList}
                  loadMore={loadMoreReportByOffice}
                  placeholder="Select Office"
                  onChange={val => {
                    if (val) {
                      changeValue('userId', '');
                      setSelectedOfficeItem(val);
                      setSelectedOfficeId(val.value);
                    }
                  }}
                  isLoading={reportsByOfficeQuery.isFetching}
                  hasNextPage={reportsByOfficeQuery.hasNextPage}
                  value={selectedOfficeItem}
                  onInputChange={handleSearchableOfficeInput}
                  isClearable
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label>Select Agent</Label>
                <span className="required">*</span>

                {/* <MultiSelectDropDown
                  options={agents}
                  placeholder="Select agent"
                  labelField="displayName"
                  valueField="id"
                  onSelect={data =>
                    data && data[0] && changeValue('userId', data[0].id)
                  }
                  value={
                    agents && agents.length > 0
                      ? agents.filter(items => items.id === formData.userId)
                      : []
                  }
                  searchable={false}
                /> */}

                <Select
                  options={updatedAgentList}
                  loadMore={loadMoreAgents}
                  placeholder="Select agent"
                  onChange={val => {
                    val && changeValue('userId', val.value);
                    val
                      ? setFormData({
                          ...formData,
                          userId: val.value,
                        })
                      : setFormData({
                          ...formData,
                          userId: null,
                        });
                  }}
                  isLoading={agentsQuery.isFetching}
                  hasNextPage={agentsQuery.hasNextPage}
                  // value={selectedAgentItem}
                  onInputChange={handleSearchableAgentInput}
                  isClearable
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <Row>
          {showSearchLoader ? (
            <div className="map-loader">
              <Spinner size="large" className="map-spinner" />
            </div>
          ) : (
            <Map
              lat={mapCenter.lat}
              lng={mapCenter.lng}
              markers={markers}
              // getDistance={getDistance}
            />
          )}
        </Row>
        {routeLocations && routeLocations.length > 0 && (
          <div className="mt-4">
            <div className="mb-4">
              <h4>Suggested Route</h4>
            </div>
            <RouteLocationsList
              locations={routeLocations}
              status={'completed'}
            />
          </div>
        )}

        <Row className="fixed-btn">
          <Col xs={12} className="header-button-sec pt-2">
            <FormGroup className="pull-right">
              <Button onClick={onCancel}>Cancel</Button>
              <Button color="primary" className="ml-3" onClick={onSave}>
                Create New Route
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Card>
      <TemplateFilterModal
        show={showModal}
        onClose={() => setShowModal(false)}
        changeValue={changeFilter}
        value={filterData}
        search={searchLocations}
        onSave={saveTemplate}
        showSpinner={showSearchLoader}
      />
      <ConfirmModal
        show={showDeleteTemplateModal}
        onClose={() => setShowDeleteTemplateModal(false)}
        title="Delete Template"
        body="Are you sure?"
        actions={[
          <Button onClick={() => confirmDeleteTemplate()} color="danger">
            Delete
          </Button>,
          <Button
            color="primary"
            onClick={() => {
              setShowDeleteTemplateModal(false);
              setSelectedTemplateID();
            }}
          >
            Cancel
          </Button>,
        ]}
      />
    </Page>
  );
};
export default CreateRoute;
