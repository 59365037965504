import http from '../http';
import urlcat from 'urlcat';

export const fetchRoutes = query => http.get(urlcat('/routes', query));

export const fetchRouteById = (routeId, query) =>
  http.get(urlcat(`/routes/${routeId}`, query));

export const deleteRoutes = ids => http.delete('/routes/delete-many', ids);

export const exportLocations = id =>
  http.get(`/route-location-maps/${id}/export`);
